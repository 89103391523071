@font-face {
  font-family: "Bosch Sans";
  src: url("./assets/fonts/BoschSans-Black.woff2") format("woff2"),
    url("./assets/fonts/BoschSans-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bosch Sans";
  src: url("./assets/fonts/BoschSans-BoldItalic.woff2") format("woff2"),
    url("./assets/fonts/BoschSans-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Bosch Sans";
  src: url("./assets/fonts/BoschSans-MediumItalic.woff2") format("woff2"),
    url("./assets/fonts/BoschSans-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Bosch Sans";
  src: url("./assets/fonts/BoschSans-BlackItalic.woff2") format("woff2"),
    url("./assets/fonts/BoschSans-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Bosch Sans";
  src: url("./assets/fonts/BoschSans-Italic.woff2") format("woff2"),
    url("./assets/fonts/BoschSans-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Bosch Sans";
  src: url("./assets/fonts/BoschSans-Light.woff2") format("woff2"),
    url("./assets/fonts/BoschSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bosch Sans";
  src: url("./assets/fonts/BoschSans-Medium.woff2") format("woff2"),
    url("./assets/fonts/BoschSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bosch Sans";
  src: url("./assets/fonts/BoschSans-Bold.woff2") format("woff2"),
    url("./assets/fonts/BoschSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Bosch Sans";
  src: url("./assets/fonts/BoschSans-LightItalic.woff2") format("woff2"),
    url("./assets/fonts/BoschSans-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Bosch Sans";
  src: url("./assets/fonts/BoschSans-Regular.woff2") format("woff2"),
    url("./assets/fonts/BoschSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: "Bosch Sans", sans-serif !important;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
