.skid-overlay {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  transition: opacity 0.3s;
  opacity: 1;
}
.skid-overlay__container {
  position: relative;
  width: 100%;
  max-width: 660px;
  min-height: 300px;
  display: flex;

  background: linear-gradient(
    93deg,
    #fff 0%,
    #fff calc(55% - 1px),
    #0f1b46 calc(55% + 1px),
    #0f1b46 100%
  );
}
.skid-overlay__client {
  box-sizing: border-box;
  width: 55%;
  padding-right: 3%;
  color: #000;
}
.skid-overlay__cta {
  position: relative;
  box-sizing: border-box;
  width: 45%;
  padding: 2em 0;
  padding-left: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.skid-overlay__logo {
  max-width: 240px;
  max-height: 2em;
}
.skid-overlay__subtitle {
  font-size: 1.2em;
  margin-top: 0.25em;
  margin-bottom: 2em;
}
.skid-overlay__cta a {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.5em 1.2em;
  border: 1px solid #fff;
  border-radius: 0.2em;
  margin-top: 0.5em;
}
.skid-overlay__cta a.skid-overlay--borderless {
  border: none;
}
.skid-overlay__close {
  cursor: pointer;
  border: none;
  background: none;
  width: 2em;
  height: 2em;
  position: absolute;
  top: 1em;
  right: 1em;
}
.skid-overlay__close::before {
  content: " ";
  display: block;
  height: 1px;
  width: 100%;
  position: absolute;
  top: calc(50% - 0.5px);
  left: 0;
  background: #979797;
  transform: rotate(45deg);
}
.skid-overlay__close::after {
  content: " ";
  display: block;
  height: 1px;
  width: 100%;
  position: absolute;
  top: calc(50% - 0.5px);
  left: 0;
  background: #979797;
  transform: rotate(-45deg);
}
.skid-overlay--screen-reader-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
@media (max-width: 520px) {
  .skid-overlay {
    display: block;
    position: fixed;
    background: #0f1b46;
  }
  .skid-overlay__container {
    background: none;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
  }
  .skid-overlay__client {
    width: 100%;
    padding: 0;
    background-color: #fff;
    flex: 1;
  }
  .skid-overlay__cta {
    width: 100%;
    padding: 1em 0;
    flex: 1;
  }
  .skid-overlay__cta::before {
    content: " ";
    position: absolute;
    left: 0;
    top: -10px;
    width: 100%;
    height: 20px;
    background: linear-gradient(
      179deg,
      #fff 0%,
      #fff calc(50% - 1px),
      #0f1b46 calc(50% + 1px),
      #0f1b46 100%
    );
  }
}

.my-custom-client-style {
  padding: 2em;
  line-height: 1.5;
}
.my-custom-client-style h2 {
  margin: 0;
  font-size: 1.2em;
  line-height: 1.2;
}
.my-custom-client-style img {
  height: 2em;
  margin-top: 1.5em;
}
.my-custom-client-style p {
  margin: 1.5em 0;
}
.my-custom-client-style a {
  font-size: 0.8em;
  color: #41aaaa;
  text-decoration: none;
}
.my-custom-client-style a:hover {
  text-decoration: underline;
}
